import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Zabieg Mikrodermabrazji | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/mikrodermabrazja/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Elektroliza = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Mikrodermabrazja</h1>

        <p className="lead">To zabieg złuszczający skórę, polegający na mechanicznym usuwaniu warstw naskórka za pomocą specjalnego urządzenia z&nbsp;głowicą diamentową. Zabieg ten może być wykonywany zarówno na twarzy, jak i&nbsp;na innych częściach ciała. Sama metoda jest bezpieczna i&nbsp;cieszy się dużym powodzeniem.</p>

        <h2>Wskazania do wykonania mikrodermabrazji to przede wszystkim:</h2>

        <ul>
          <li>rozszerzone pory</li>
          <li>zmarszczki</li>
          <li>przebarwienia skóry</li>
          <li>blizny potrądzikowe</li>
          <li>trądzik</li>
          <li>przebarwienia po opalaniu</li>
          <li>zwiotczała skóra</li>
        </ul>

        <p>Przebieg zabiegu polega na naniesieniu na skórę specjalnego kosmetyku złuszczającego i&nbsp;przeprowadzeniu mikrodermabrazji za pomocą urządzenia. Zabieg trwa zazwyczaj od 15 do 60 minut, w&nbsp;zależności od obszaru ciała, na którym jest wykonywany. Po zabiegu skóra może być lekko zaczerwieniona i&nbsp;napięta, jednak efekty są widoczne już po pierwszym zabiegu.</p>

        <h2>Po zabiegu</h2>

        <ul>
          <li>należy unikać słońca</li>
          <li>zrezygnować z&nbsp;sauny i&nbsp;solarium</li>
          <li>wskazane jest używanie kremów z filtrami UV</li>
          <li>neleży dobrze nawilżać skórę</li>
        </ul>

        <h2>Przeciwwskazania do wykonania mikrodermabrazji to między innymi:</h2>

        <ul>
          <li>choroby skóry, takie jak łuszczyca czy trądzik różowaty</li>
          <li>opryszczka</li>
          <li>ciąża, okres karmienia</li>
          <li>stosowanie retinoidów</li>
          <li>skłonność do bliznowców</li>
          <li>stany zapalne skóry</li>
          <li>choroby nowotworowe</li>
        </ul>

        <p>Przed wykonaniem mikrodermabrazji należy dokładnie omówić swoje wskazania i&nbsp;przeciwwskazania z&nbsp;kosmetologiem, który będzie wykonywał zabieg.</p>

        <h2>Cena: od 180 zł.</h2>

        <h2>Jak przebiega zabieg mikrodermabrazji?</h2>

        <iframe
          width="800"
          height="500"
          src="https://www.youtube.com/embed/Qy157t1N9kg"
          title="Mikrodermabrazja diamentowa"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default Elektroliza;
